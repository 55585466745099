/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'
import axios from '@axios'
import Vue from 'vue'
import moment from 'moment'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// import $ from 'jquery'

export default function useCalendarEventHandler(props, clearForm, formObserver) {
  const toast = useToast()

  // ------------------------------------------------
  // eventLocal
  // ------------------------------------------------
  const eventLocal = ref(computed(() => store.state.caregiverShift.event))
  // const eventLocal = ref(store.state.caregiverShift.event)
  // console.log('store.state.caregiverShift.event', store.state.caregiverShift.event)
  const resetEventLocal = () => {
    eventLocal.value = ref(computed(() => store.state.caregiverShift.event))
  }
  const isEventHandlerSidebarActive = ref(computed(() => store.state.caregiverShift.isEventHandlerSidebarActive))

  watch(isEventHandlerSidebarActive, val => {
    // ? Don't reset event till transition is finished
    const appContainer = document.querySelector('#app')
    if (!val) {
      clearForm.value()
      appContainer.style.overflow = 'scroll'
      caregiverTaskReset()
    } else {
      appContainer.style.overflow = 'hidden'
    }
  })

  const onSubmit = () => {
    let validationCount = 0
    if (eventLocal.value.extendedProps.status === '8' && eventLocal.value.extendedProps.caregiver === '') {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Please Select Caregiver! ',
          icon: 'XIcon',
          variant: 'danger',
        },
      })
      store.commit('caregiverShift/openShift')
      validationCount += 1
    } if (moment(eventLocal.value.end) <= moment(eventLocal.value.start)) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'End Date must be greater than start date. ',
          icon: 'XIcon',
          variant: 'danger',
        },
      })
      store.commit('caregiverShift/openShift')
      validationCount += 1
    } if (moment(eventLocal.value.extendedProps.repeat_until) < moment(eventLocal.value.end)) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Repeat Until date must be greater than end date. ',
          icon: 'XIcon',
          variant: 'danger',
        },
      })
      store.commit('caregiverShift/openShift')
      validationCount += 1
    }
    if (validationCount === 0) {
      if (eventLocal.value.extendedProps.recurrence_pattern === 'daily') {
        if (eventLocal.value.extendedProps.dailyPattern.pattern === 'every') {
          eventLocal.value.extendedProps.pattern_option = { every: eventLocal.value.extendedProps.dailyPattern.day }
        } else {
          eventLocal.value.extendedProps.pattern_option = { every: eventLocal.value.extendedProps.dailyPattern.pattern }
        }
      } else if (eventLocal.value.extendedProps.recurrence_pattern === 'weekly') {
        eventLocal.value.extendedProps.pattern_option = {
          every: eventLocal.value.extendedProps.weeklyPattern.every_week,
          days: eventLocal.value.extendedProps.weeklyPattern.days,
        }
      } else if (eventLocal.value.extendedProps.recurrence_pattern === 'monthly') {
        if (eventLocal.value.extendedProps.monthlyPattern.pattern === 'pattern1') {
          eventLocal.value.extendedProps.pattern_option = {
            day: eventLocal.value.extendedProps.monthlyPattern.input_one,
            every: eventLocal.value.extendedProps.monthlyPattern.input_two,
          }
        } else {
          eventLocal.value.extendedProps.pattern_option = {
            nth: eventLocal.value.extendedProps.monthlyPattern.selected1,
            day: eventLocal.value.extendedProps.monthlyPattern.selected2,
            every: eventLocal.value.extendedProps.monthlyPattern.input_three,
          }
        }
      } else if (eventLocal.value.extendedProps.yearlyPattern.pattern === 'pattern1') {
        if (eventLocal.value.extendedProps.yearlyPattern.input_two < 10) {
          eventLocal.value.extendedProps.yearlyPattern.input_two = `0${eventLocal.value.extendedProps.yearlyPattern.input_two}`
        }
        eventLocal.value.extendedProps.pattern_option = {
          every: eventLocal.value.extendedProps.yearlyPattern.recur_every,
          specific_day: `${eventLocal.value.extendedProps.yearlyPattern.selected1}-${eventLocal.value.extendedProps.yearlyPattern.input_two}`,
        }
      } else {
        eventLocal.value.extendedProps.pattern_option = {
          every: eventLocal.value.extendedProps.yearlyPattern.recur_every,
          nth: eventLocal.value.extendedProps.yearlyPattern.selected2,
          day: eventLocal.value.extendedProps.yearlyPattern.selected3,
          month: eventLocal.value.extendedProps.yearlyPattern.selected4,
        }
      }
      // eslint-disable-next-line no-use-before-define
      eventLocal.value.extendedProps.caregiverTask = selectedTask.value
      const eventData = eventLocal.value
      const actionSubmit = store.state.caregiverShift.isMail ? 'testCalendar' : (eventData.id ? 'updateEvent' : 'addEvent')

      // console.log('actionSubmit', actionSubmit, eventData)
      store.dispatch('caregiverShift/submit', { data: eventData, action: actionSubmit })
        .then(res => {
          // console.log('res.data.status', res.data.response)
          store.commit('caregiverShift/setIsEventHandlerSidebarActive', false)

          if (res.data.response.status === 200) {
            store.dispatch('caregiverShift/popupNotif', {
              icon: 'success',
              title: 'Saved!',
              text: eventData.id ? 'Updated succesfully.' : 'Added succesfully.',
              showConfirmButton: false,
              timer: 1500,
            })
            store.dispatch('caregiverShift/sendSockMsg', { id: store.state.caregiverShift.notificationSockId, sockMsg: true })
          } else {
            store.dispatch('caregiverShift/popupNotif', {
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              showConfirmButton: false,
              timer: 1500,
            })
          }
        })
    }
  }
  const createTask = ref(false)
  const selectedTask = ref([])
  const taskOptions = ref([])
  const shiftsTaskOptions = ref([])

  watch(eventLocal, event => {
    taskOptions.value = []
    if (event.extendedProps.shift_type === 'previous') {
      taskOptions.value = event.extendedProps.shift_tasks
    } else {
      shiftsTaskOptions.value = event.extendedProps.shift_tasks
    }
    createTask.value = false
    // $('a.fc-daygrid-more-link.fc-more-link').on('click', element => { console.log('element.parent()', element) })
  })

  const caregiverTask = ref({
    task: null,
    details: null,
    start_time: null,
  })
  const caregiverTaskReset = () => {
    caregiverTask.value = {}
  }

  const createTaskCancel = () => {
    createTask.value = false
    caregiverTask.value = {}
  }

  const pushCaregiverTask = task => {
    // console.log('task', task)
    if (task.task && task.details && task.start_time) {
      if (task.action !== 'edit') {
        shiftsTaskOptions.value.push(task)
      }
    } else if (task.description) {
    }
    caregiverTaskReset()
    createTask.value = false
  }
  const removeShifTask = ShifTask => {
    const tempShiftsTask = shiftsTaskOptions.value.filter(f => f.task !== ShifTask.task)
    shiftsTaskOptions.value = tempShiftsTask
  }

  const fetchShiftTasks = () => {
    // console.log(eventLocal.value.id)
    if (!eventLocal.value.id) {
      // console.log('make req')
      getClientAddress()
      axios
        .post(`${Vue.prototype.$apiUrl2}calendar/getClientCareplanData`, {
          param: {
            clientId: eventLocal.value.extendedProps.client,
          },
        },
        {
          headers: {
            'Content-type': 'application/json',
          },
        })
        .then(response => {
          // debugger
          // console.log(response.data.response.data)
          // eventLocal.value.extendedProps.shift_tasks = response.data.response.data
          shiftsTaskOptions.value = response.data.response.data
          // console.log('dsadsadsa', shiftsTaskOptions.value)
          // createTask.value = false
        })
        .catch(error => {
          // console.log(error)
        })
    }
  }

  const getClientAddress = () => {
    axios
      .post(`${Vue.prototype.$apiUrl2}calendar/getClientAddress`, {
        clientId: eventLocal.value.extendedProps.client,
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
      .then(response => {
        // console.log(response.data.response.data)
        eventLocal.value.extendedProps.location = response.data.response.data
      })
      .catch(error => {
        // console.log(error)
      })
  }

  const checkRequiredForms = () => {
    // console.log(formObserver.value.value)
    formObserver.value.value.validate().then(success => {
      if (!success) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Please complete all the required forms! ',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        store.commit('caregiverShift/openShift')
      } else {
        onSubmit()
      }
    })
  }

  return {
    eventLocal,
    resetEventLocal,
    // calendarOptions,
    /* tasks */
    createTask,
    selectedTask,
    shiftsTaskOptions,
    taskOptions,
    removeShifTask,
    caregiverTask,
    pushCaregiverTask,
    createTaskCancel,
    fetchShiftTasks,
    checkRequiredForms,

    onSubmit,
  }
}
