<template>
  <div class="mb-5">
    <b-img
      center
      :src="inactiveImg"
      v-bind="imgProps"
    />
    <div class="text-center">
      <span class="text-title">
        Account Inactive
      </span><br>
      <span class="text-content">
        This profile has been deactivated. You have the option to activate this caregiver.
      </span>
    </div>
  </div>
</template>
<script>
import { BImg } from 'bootstrap-vue'
import Vue from 'vue'

export default {
  components: {
    BImg,
  },
  setup() {
    return {
      // eslint-disable-next-line global-require
      // inactiveImg: require('@/assets/images/caregiver/caregiver_disabled_account.png'),
      inactiveImg: `${Vue.prototype.$mainUrl}img/account_inactive.svg`,
      imgProps: {
        center: true,
        width: 250,
        class: 'my-2',
      },
    }
  },
}
</script>
<style lang="scss">
    [dir] .text-center{
        font-style: normal;
        .text-title {
        text-align: center;
        font-weight: 500;
        font-size:24px !important;
        line-height: 18px;
        letter-spacing: 0px;
        opacity: 1;
    }
        [dir] .text-content {
        text-align: center;
        font-weight: normal;
        font-size:15px !important;
        line-height: 18px;
        letter-spacing: 0px;
        opacity: 1;
    }
    }
</style>
