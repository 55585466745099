/* eslint-disable import/no-extraneous-dependencies */
<template>
  <div>
    <div class="border-top">
      <div class="my-2">
        <div
          class="d-flex legend"
        >
          <span>
            <div class="text-nowrap">
              <feather-icon
                icon="CompassIcon"
                class="mr-75"
                size="17"
              />
              <span class="title mr-75">
                Legend
              </span>
            </div>
          </span>
          <!-- <b-badge variant="success">
            Completed
          </b-badge> -->
          <span>
            <span
              v-for="legend in $store.state.caregiverShift.calendarOptions"
              :key="legend.id"
            >
              <b-badge
                :class="`bg-light-${legend.class}${1}
                mr-75
                legend-badge
                outlined-${legend.id}
                `"
                @click="filterShifts(legend.id)"
              >
                <span class="badge-statue-font-color">
                  {{ legend.label }}
                </span>
              </b-badge>
            </span>
          </span>
        </div>
      </div>
      <div class="border rounded">
        <div class="my-0">
          <full-calendar
            ref="refCalendar"
            :options="calendarOptions"
            class="full-calendar"
          />
        </div>
      </div>
    </div>
    <b-modal
      id="see-more-modal"
      v-model="seeMoreModal"
      centered
      hide-footer
    />
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import { BBadge, BModal } from 'bootstrap-vue'
import useCaregiverShiftCalendar from './useCaregiverShiftCalendar'

export default ({
  components: {
    BBadge,
    FullCalendar,
    BModal,
  },
  setup() {
    const {
      // variables
      // methods
      calendarOptions,
      refCalendar,
      seeMoreModal,
      filterShifts,
    } = useCaregiverShiftCalendar()

    return {
      calendarOptions,
      refCalendar,
      seeMoreModal,
      filterShifts,
    }
  },
})
</script>
<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
@import "./calendar.scss";
.tag-label,
.task-filter {
  text-align: left;
  font: normal normal normal 14px/24px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
}
.list-group-item {
  border: 0;
  cursor: pointer;
}
.fc-event-main{
  overflow: hidden !important;
}

[dir] .bg-light-completed1 {
    background:#B7CF3E1F !important;
    border: 1px solid #B7CF3E !important;
    .fc-event-main{
      color: #B7CF3E !important;
    }
}
[dir] .bg-light-scheduled1 {
    background: #01BCD61F !important;
    border: 1px solid #01bcd6 !important;
    .fc-event-main{
      color: #01BCD6 !important;
    }
}
[dir] .bg-light-in-progress1 {
    background: #FFCE2D1F !important;
    border: 1px solid #FFCE2D !important;
    .fc-event-main{
      color: #FFCE2D !important;
    }
}
[dir] .bg-light-m-clock-in1, .bg-light-m-clock-out1, .bg-light-attention-required1  {
    background:#FDEAEA !important;
    border: 1px solid #EA5455 !important;
    .fc-event-main{
      color: #EA5455 !important;
    }
}
[dir] .bg-light-open-shift1 {
    background: #E736841F !important;
    border: 1px solid #E73684 !important;
    .fc-event-main{
      color: #E63583 !important;
    }
}
[dir] .bg-light-c-client1 {
    background: #787b7e1f !important;
    border: 1px solid #B1BAC3 !important;
    .fc-event-main{
      color: #B1BAC3 !important;
    }
}
[dir] .bg-light-c-caregiver1 {
    background: #FFEFE0 !important;
    border: 1px solid #FF7D01 !important;
    .fc-event-main{
      color: #FF7D01 !important;
    }
}
[dir] .bg-light-pending-confirmation1 {
    background: #7566E81F !important;
    border: 1px solid #7566E8 !important;
    .fc-event-main{
      color: #7566E8 !important;
    }
}
[dir] .fc-dayGridDay-button, .fc-dayGridWeek-button, .fc-dayGridMonth-button{
    padding: 0.55rem 1.5rem !important;
}
[dir] .legend{
    .title{
      font-weight: 600;
      line-height: 24px;
      font-size: 13px;
    }
   .bg-light-completed1{
     background: #B7CF3E !important;
   }
   .bg-light-scheduled1{
     background: #01BCD6 !important;
   }
   .bg-light-in-progress1{
     background: #FFCE2D !important;
   }
   .bg-light-m-clock-in1, .bg-light-m-clock-out1, .bg-light-attention-required1  {
    background: #EA5455 !important;
   }
   .bg-light-open-shift1{
     background: #E63583 !important;
   }
   .bg-light-c-client1{
     background: #B1BAC3 !important;
   }
   .bg-light-c-caregiver1{
     background: #FF7D01 !important;
   }
   .bg-light-pending-confirmation1{
     background: #7566E8 !important;
   }
   .badge-statue-font-color{
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0px;
    color: #FFFFFF;
  }
  .bg-missed{
    background: #ea5455 !important;
  }
}

/* [dir] h1, h2, h3, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
  color: #33475B !important;
} */
span.badge.mr-1 {
    margin-bottom: 5px!important;
}
span.fc-timegrid-axis-cushion.fc-scrollgrid-shrink-cushion.fc-scrollgrid-sync-inner {
    height: 55px!important;
}
</style>
<style scoped>
  [dir] .legend-badge {
    cursor: pointer;
    margin-bottom: 5px;
    filter: brightness(100%);
  }
  [dir] .outlined-0 {
    background: #F4F8F9 0% 0% no-repeat padding-box !important;
    border: 1px solid #329D9C !important;
  }
  [dir] .outlined-0 > span {
    color: #329D9C !important;
  }
  [dir] .outlined-1 {
    background: #01BCD61F 0% 0% no-repeat padding-box !important;
    border: 1px solid #01BCD6 !important;
  }
  [dir] .outlined-1 > span {
    color: #01BCD6 !important;
  }
  [dir] .outlined-2 {
    background: #FFCE2D1F 0% 0% no-repeat padding-box !important;
    border: 1px solid #FFCE2D !important;
  }
  [dir] .outlined-2 > span {
    color: #FFCE2D !important;
  }
  [dir] .outlined-3 {
    background: #B7CF3E1F 0% 0% no-repeat padding-box !important;
    border: 1px solid #B7CF3E !important;
  }
  [dir] .outlined-3 > span {
    color: #B7CF3E !important;
  }
  [dir] .outlined-4 {
    background: #fdeaea 0% 0% no-repeat padding-box !important;
    border: 1px solid #ea5455 !important;
  }
  [dir] .outlined-4 > span {
    color: #ea5455 !important;
  }
  [dir] .outlined-5 {
    background: #fdeaea 0% 0% no-repeat padding-box !important;
    border: 1px solid #ea5455 !important;
  }
  [dir] .outlined-5 > span {
    color: #ea5455 !important;
  }
  [dir] .outlined-6 {
    background: #E736841F 0% 0% no-repeat padding-box !important;
    border: 1px solid #E73684 !important;
  }
  [dir] .outlined-6 > span {
    color: #E73684 !important;
  }
  [dir] .outlined-7 {
    background: #B1BAC41F 0% 0% no-repeat padding-box !important;
    border: 1px solid #B1BAC4 !important;
  }
  [dir] .outlined-7 > span {
    color: #B1BAC4 !important;
  }
  [dir] .outlined-8 {
    background: #FF7D011F 0% 0% no-repeat padding-box !important;
    border: 1px solid #FF7D01 !important;
  }
  [dir] .outlined-8 > span {
    color: #FF7D01 !important;
  }
  [dir] .outlined-10 {
    background: #EEECFD 0% 0% no-repeat padding-box !important;
    border: 1px solid #7566E8 !important;
  }
  [dir] .outlined-10 > span {
    color: #7566E8 !important;
  }
</style>
