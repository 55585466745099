<template>
  <div v-if="fetchingData === false">
    <caregiver-nav class="mb-2" />
    <template v-if="userData">
      <!-- Caregiver Profile -->
      <b-row>
        <b-col
          cols="12"
        >
          <user-view-user-info-card />
        </b-col>
      </b-row>
      <!-- Shift Calendar  -->

      <b-overlay
        :show="$store.state.caregiverShift.isLoadingCalendar"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="lg"
      >
        <b-row v-if="['Active', 'Applicant', 'New', 'Pending'].includes($store.state.caregiver.caregiver.employmentstatus)">
          <b-col cols="12">
            <b-card-actions
              title="Shift Calendar"
              class="cgiver_cal"
              action-collapse
            >
              <template>
                <caregiver-shift-calendar />
              </template>
            </b-card-actions>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col>
            <caregiver-account-invalid />
          </b-col>
        </b-row>
      </b-overlay>

    </template>
    <calendar-event-handler />
    <caregiver-handler
      v-model="$store.state.caregiver.isCaregiverHandlerSidebarActive"
      @toast-notif="toastNotif"
    />
  </div>
  <div
    v-else
    class="text-center pt-5"
  >
    <b-spinner
      style="width: 3rem; height: 3rem;"
      type="grow"
    />
    <strong class="d-block mt-2">Loading Information...</strong>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import moment from 'moment'
import {
  // ref, computed, onUnmounted,
  ref, computed,
} from '@vue/composition-api'
import {
  BRow, BCol, BOverlay, BSpinner,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import CaregiverStoreModule from '../CaregiverStoreModule'
// import CaregiverShiftStoreModule from './shiftCalendar/CaregiverShiftStoreModule'
import caregiverStoreInit from '../caregiverStoreInit'
import CaregiverNav from '../CaregiverNav.vue'
import CaregiverShiftCalendar from './shiftCalendar/CaregiverShiftCalendar.vue'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import CaregiverHandler from '../CaregiverHandler.vue'
import CaregiverAccountInvalid from '../AccountInactive.vue'

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BOverlay,
    BSpinner,

    // Local Components
    CaregiverNav,
    UserViewUserInfoCard,
    CaregiverShiftCalendar,
    CalendarEventHandler,
    CaregiverHandler,
    CaregiverAccountInvalid,
  },
  setup() {
    const toast = useToast()

    caregiverStoreInit() /* initialized stores */

    // const CAREGIVER_APP_STORE_MODULE_NAME = 'caregiver'
    // const CAREGIVERSHIFT_APP_STORE_MODULE_NAME = 'caregiverShift'

    // // Register module caregiver
    // if (!store.hasModule(CAREGIVER_APP_STORE_MODULE_NAME)) {
    //   store.registerModule(
    //     CAREGIVER_APP_STORE_MODULE_NAME,
    //     CaregiverStoreModule,
    //   )
    // }

    // // Register module caregiverShift
    // if (!store.hasModule(CAREGIVERSHIFT_APP_STORE_MODULE_NAME)) {
    //   store.registerModule(
    //     CAREGIVERSHIFT_APP_STORE_MODULE_NAME,
    //     CaregiverShiftStoreModule,
    //   )
    // }

    // // UnRegister on leave caregiver
    // onUnmounted(() => {
    //   if (store.hasModule(CAREGIVER_APP_STORE_MODULE_NAME)) {
    //     store.unregisterModule(CAREGIVER_APP_STORE_MODULE_NAME)
    //   }
    // })

    // // UnRegister on leave caregiverShift
    // onUnmounted(() => {
    //   if (store.hasModule(CAREGIVERSHIFT_APP_STORE_MODULE_NAME)) {
    //     store.unregisterModule(CAREGIVERSHIFT_APP_STORE_MODULE_NAME)
    //   }
    // })

    const userData = ref(computed(() => store.state.caregiver.caregiver))
    const fetchingData = ref(true)

    const fetchCaregiver = () => {
      fetchingData.value = true
      const formData = new FormData()
      const data = JSON.stringify({
        action: 'getById1',
        id: router.currentRoute.params.caregiverId,
      })
      formData.append('data', data)

      store.dispatch('caregiver/request', formData)
        .then(response => {
          store.commit('caregiver/SET_caregiver', response.data.response.data.caregiver)
          response.data.response.data.shifts.forEach((f, i) => {
            // eslint-disable-next-line no-param-reassign
            response.data.response.data.shifts[i].tags = typeof response.data.response.data.shifts[i].tags === 'string' ? JSON.parse(response.data.response.data.shifts[i].tags) : response.data.response.data.shifts[i].tags
            // eslint-disable-next-line no-param-reassign
            response.data.response.data.shifts[i].title = ` ${f.client_fullname}` /* set title as start to end time and client name */
            // response.data.response.data.shifts[i].title = `${response.data.response.data.shifts[i].id} ${moment(response.data.response.data.shifts[i].start_time, ['HH.mm']).format('hha')}-${moment(response.data.response.data.shifts[i].end_time, ['HH.mm']).format('hha')} ${f.client_fullname}` /* set title as start to end time and client name */
            // response.data.response.data.shifts[i].title = `${f.client_fullname}`
            // eslint-disable-next-line no-param-reassign
            response.data.response.data.shifts[i].caregiver = store.state.caregiver.caregiver.id
          })
          store.commit('caregiverShift/setShifts', response.data.response.data.shifts)
          store.commit('caregiverShift/SET_LOADING_CALENDAR', false)
          fetchingData.value = false
          // test()
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          console.log(error)
          // window.location.replace('/caregiver')
          fetchingData.value = false
        })
    }
    const isEventHandlerSidebarActive = ref(computed(() => (store.state.caregiverShift.isEventHandlerSidebarActive)))

    store.dispatch('caregiverShift/getCalendarOptions') /* Get Calendar options */

    fetchCaregiver()

    const toastNotif = (title, text, icon, variant) => {
      toast({
        component: ToastificationContent,
        props: {
          title, text, icon, variant,
        },
      })
    }
    store.dispatch('caregiverShift/initializeSockJs', { id: store.state.caregiverShift.notificationSockId })

    return {
      userData,
      isEventHandlerSidebarActive,
      toastNotif,
      moment,
      fetchingData,
    }
  },
}
</script>

<style lang="scss" scope>

@import "@core/scss/vue/apps/calendar.scss";
// used in calendar mail icon
  @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');
  @import "@core/scss/vue/apps/calendar.scss";

  @media (max-width: 1480px){
    [dir] .legend-badge{
        margin-bottom: 5px !important;
    }
  }

  .tag-label,
  .task-filter {
    text-align: left;
    font: normal normal normal 14px/24px Montserrat;
    letter-spacing: 0px;
    opacity: 1;
  }
  .list-group-item {
    border: 0;
    cursor: pointer;
  }
  strong {
      font-size: large;
  }

  .fc-event-main{
    overflow: hidden !important;
  }

  .fc .fc-toolbar h2 {
    font-size: 1.45rem;
  }

  .cgiver_cal {
    .card {
      .card-title {
        font-size: 1.1rem;
      }
    }
  }

  [dir] .bg-light-completed {
      background:#B7CF3E1F !important;
      border: 1px solid #B7CF3E !important;
      color: #B7CF3E !important;
      .fc-event-main{
        color: #B7CF3E !important;
      }
  }
  [dir] .bg-light-scheduled {
      background: #01BCD61F !important;
      border: 1px solid #01bcd6 !important;
      color: #01BCD6 !important;
      .fc-event-main{
        color: #01BCD6 !important;
      }
  }
  [dir] .bg-light-in-progress {
      background: #FFCE2D1F !important;
      border: 1px solid #FFCE2D !important;
      color: #FFCE2D !important;
      .fc-event-main{
        color: #FFCE2D !important;
      }
  }
  [dir] .bg-light-m-clock-in, .bg-light-m-clock-out, .bg-light-attention-required  {
      background:#FDEAEA !important;
      border: 1px solid #EA5455 !important;
      color: #EA5455 !important;
      .fc-event-main{
        color: #EA5455 !important;
      }
  }
  [dir] .bg-light-open-shift {
      background: #E736841F !important;
      border: 1px solid #E73684 !important;
      color: #E63583 !important;
      .fc-event-main{
        color: #E63583 !important;
      }
  }
  [dir] .bg-light-c-client {
      background: #787b7e1f !important;
      border: 1px solid #B1BAC3 !important;
      color: #B1BAC3 !important;
      .fc-event-main{
        color: #B1BAC3 !important;
      }
  }
  [dir] .bg-light-c-caregiver {
      background: #FFEFE0 !important;
      border: 1px solid #FF7D01 !important;
      color: #FF7D01 !important;
      .fc-event-main{
        color: #FF7D01 !important;
      }
  }
  [dir] .bg-light-pending-confirmation {
      background: #7566E81F !important;
      border: 1px solid #7566E8 !important;
      color: #7566E8 !important;
      .fc-event-main{
        color: #7566E8 !important;
      }
  }
  [dir] .fc-dayGridDay-button, .fc-dayGridWeek-button, .fc-dayGridMonth-button{
      padding: 0.55rem 1.5rem !important;
  }
  // [dir] span .bg-light-completed{
  //   background:
  // }
  [dir] .legend{
      .title{
        font-weight: 600;
        line-height: 24px;
        font-size: 13px;
      }
    .bg-light-completed{
      background: #B7CF3E !important;
    }
    .bg-light-scheduled{
      background: #01BCD6 !important;
    }
    .bg-light-in-progress{
      background: #FFCE2D !important;
    }
    .bg-light-open-shift{
      background: #E63583 !important;
    }
    .bg-light-c-client{
      background: #B1BAC3 !important;
    }
    .bg-light-c-caregiver{
      background: #FF7D01 !important;
    }
    .bg-light-pending-confirmation{
      background: #7566E8 !important;
    }
    .bg-light-m-clock-in, .bg-light-m-clock-out, .bg-light-attention-required  {
      background:#EA5455 !important;
  }
    .badge-statue-font-color{
      text-align: left;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0px;
      color: #FFFFFF;
    }
  }

  [dir] body{
      /* color: #33475B !important;

      h1, h2, h3, h4, h5{
        color: #33475B !important;
      } */
      .btn{
        font-weight: normal;
        font-size: 14px;
      }

    .fc-daygrid-day-frame{
        max-height: 10px !important;
      }
  }
  .app-calendar-sidebar{
    background: transparent;
  }
  [dir] .fc-event-title{
    overflow: hidden !important;
    padding: 0px;
    margin: 0px;
    font: normal normal 600 11px/14px Montserrat !important;
  }

  [dir]  .fc-title:before {
    font-family: "Font Awesome 5 Free";
    content: "\f274";
    display: inline-block;
    padding-right: 3px;
    font-weight: 900;
  }

  // removed blue dot on calendar
  [dir] .fc-daygrid-event-dot{
    display: none !important;
  }

  [dir] .title-event{
    overflow: hidden !important;
    // font: normal normal 600 11px/14px Montserrat;

  }
  [dir] #see-more-modal___BV_modal_content_ .title-event{
    font: normal normal 600 14px/24px Montserrat;
  }

</style>
