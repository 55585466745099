/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import store from '@/store'
// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

import { ref, computed, onMounted } from '@vue/composition-api'
import moment from 'moment'

export default function useCaregiverShiftCalendar() {
  const refCalendar = ref(null)

  const seeMoreModal = ref(false)

  let calendarApi = null

  onMounted(() => {
    calendarApi = refCalendar.value.getApi()
    document.querySelector('[dir] .legend .bg-light-show-all1').style.filter = 'brightness(100%)'
  })
  const caregiverShifts = ref(store.state.caregiverShift.shifts)
  // caregiverShifts.value = computed(() => store.state.caregiverShift.shifts)
  const calendarsColor = {
    1: 'scheduled',
    2: 'in-progress',
    3: 'completed',
    4: 'm-clock-in',
    5: 'm-clock-out',
    6: 'open-shift',
    7: 'c-client',
    8: 'c-caregiver',
    9: 'tentative-not-scheduled',
    10: 'pending-confirmation',
    11: 'attention-required',
  }
  const openMail = calendarEvent => {
    // store.commit('caregiverShift/setEvent', calendarEvent)
    // console.log('caregiverShift/setEvent', calendarEvent)
    store.commit('caregiverShift/setIsEventHandlerSidebarActive', true)
    store.commit('caregiverShift/openMail')
  }

  const grabEventDataFromEventApi = eventApi => {
    const {
      id,
      title,
      start,
      end,
      start_time,
      end_time,
      extendedProps: {
        shift_type,
        status,
        recurrence,
        isRecurr,
        recurrence_pattern,
        pattern_option,
        repeat_every,
        repeat_on,
        client_fullname,
        repeat_until,
        client,
        caregiver,
        confirmation,
        clientRate,
        authorization,
        caregiverRate,
        mileage,
        location,
        tags,
        reason,
        caregiverTask,
        shift_tasks,
        orig_start,
        orig_end,

        // For send mail data
        delivery,
        userEmail,
        caregiverEmail,
        relateTo,
        clientEmail,
        subject,
        message,
        signature,
        files,
      },
      allDay,
    } = eventApi
    // console.log('eventApi', eventApi)

    return {
      id,
      title,
      start,
      end,
      start_time,
      end_time,
      extendedProps: {
        shift_type,
        status,
        recurrence,
        isRecurr,
        recurrence_pattern,
        pattern_option,
        repeat_every,
        repeat_on,
        client_fullname,
        repeat_until,
        client,
        caregiver,
        confirmation,
        clientRate,
        authorization,
        caregiverRate,
        mileage,
        location,
        tags,
        reason,
        caregiverTask,
        shift_tasks,
        orig_start,
        orig_end,
        // For send mail data
        delivery,
        userEmail,
        caregiverEmail,
        relateTo,
        clientEmail,
        subject,
        message,
        signature,
        files,
      },
      allDay,
    }
  }

  const grabEventfromModal = shift => {
    const {
      id,
      title,
      start,
      end,
      start_time,
      end_time,
      shift_type,
      status,
      recurrence,
      isRecurr,
      recurrence_pattern,
      pattern_option,
      repeat_every,
      repeat_on,
      client_fullname,
      repeat_until,
      client,
      caregiver,
      confirmation,
      clientRate,
      authorization,
      caregiverRate,
      mileage,
      location,
      tags,
      reason,
      caregiverTask,
      shift_tasks,
      orig_start,
      orig_end,
      // For send mail data
      delivery,
      userEmail,
      caregiverEmail,
      relateTo,
      clientEmail,
      subject,
      message,
      signature,
      files,
      allDay,
    } = shift[0]
    return {
      id,
      title,
      start,
      end,
      start_time,
      end_time,
      extendedProps: {
        shift_type,
        status,
        recurrence,
        isRecurr,
        recurrence_pattern,
        pattern_option,
        repeat_every,
        repeat_on,
        client_fullname,
        repeat_until,
        client,
        caregiver,
        confirmation,
        clientRate,
        authorization,
        caregiverRate,
        mileage,
        location,
        tags,
        reason,
        caregiverTask,
        shift_tasks,
        orig_start,
        orig_end,
        // For send mail data
        delivery,
        userEmail,
        caregiverEmail,
        relateTo,
        clientEmail,
        subject,
        message,
        signature,
        files,
      },
      allDay,
    }
  }

  const setRecurrInput = tempEvent => {
    const tempEventRaw = tempEvent
    tempEvent.extendedProps.dailyPattern = { pattern: 'every', day: '1' }
    tempEvent.extendedProps.weeklyPattern = { every_week: '1', days: [] }
    tempEvent.extendedProps.monthlyPattern = {
      pattern: 'pattern1',
      input_one: '',
      input_two: '',
      selected1: [],
      selected2: [],
      input_three: '',
    }
    tempEvent.extendedProps.yearlyPattern = {
      recur_every: '1',
      pattern: 'pattern1',
      input_one: '1',
      input_two: '2',
      selected1: [],
      selected2: [],
      selected3: [],
      selected4: [],
    }

    // ASSIGNING RECURRENCE VALUE FORMAT
    if (tempEventRaw.extendedProps.recurrence_pattern === 'daily') { // IF PATTERN IS DAILY
      if (tempEventRaw.extendedProps.pattern_option.every === 'weekday') {
        tempEvent.extendedProps.dailyPattern = { pattern: tempEventRaw.extendedProps.pattern_option.every, day: '' }
      } else {
        tempEvent.extendedProps.dailyPattern = { pattern: 'every', day: tempEventRaw.extendedProps.pattern_option.every }
      }
    } else if (tempEventRaw.extendedProps.recurrence_pattern === 'weekly') { // IF PATTERN IS WEEKLY
      tempEvent.extendedProps.weeklyPattern = { every_week: tempEventRaw.extendedProps.pattern_option.every, days: tempEventRaw.extendedProps.pattern_option.days }
    } else if (tempEventRaw.extendedProps.recurrence_pattern === 'monthly') { // IF PATTERN IS MONTHLY
      if (tempEventRaw.extendedProps.pattern_option.nth === undefined) {
        tempEvent.extendedProps.monthlyPattern = {
          pattern: 'pattern1',
          input_one: tempEventRaw.extendedProps.pattern_option.day,
          input_two: tempEventRaw.extendedProps.pattern_option.every,
        }
      } else {
        tempEvent.extendedProps.monthlyPattern = {
          pattern: 'pattern2',
          selected1: tempEventRaw.extendedProps.pattern_option.nth,
          selected2: tempEventRaw.extendedProps.pattern_option.day,
          input_three: tempEventRaw.extendedProps.pattern_option.every,
        }
      }
    } else if (tempEventRaw.extendedProps.recurrence_pattern === 'yearly') { // IF PATTERN IS YEARLY
      tempEventRaw.extendedProps.pattern_option = JSON.parse(tempEventRaw.extendedProps.pattern_option)
      // eslint-disable-next-line no-lonely-if
      if (tempEventRaw.extendedProps.pattern_option.nth === undefined) {
        // console.log(tempEventRaw.extendedProps.pattern_option.nth)
        const inputs = tempEventRaw.extendedProps.pattern_option.specific_day.split('-')
        // console.log(inputs)
        tempEvent.extendedProps.yearlyPattern = {
          pattern: 'pattern1',
          input_one: tempEventRaw.extendedProps.pattern_option.every,
          selected1: inputs[0],
          input_two: inputs[1],
        }
      } else {
        tempEvent.extendedProps.yearlyPattern = {
          pattern: 'pattern2',
          recur_every: tempEventRaw.extendedProps.pattern_option.every,
          selected2: tempEventRaw.extendedProps.pattern_option.nth,
          selected3: tempEventRaw.extendedProps.pattern_option.day,
          selected4: tempEventRaw.extendedProps.pattern_option.month,
        }
      }
    }
    // console.log('tempEvent', tempEvent)
    return tempEvent
  }
  // console.log(store.state.caregiverShift.shifts)
  const calendarOptions = ref({
    // plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    // initialView: 'dayGridMonth',
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: 'dayGridMonth',
    // initialView: 'timeGridWeek',
    headerToolbar: {
      start: 'sidebarToggle, prev, next, title',
      // end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
      end: '',
    },
    events: computed(() => caregiverShifts.value),

    displayEventTime: false,

    displayEventEnd: false,

    editable: false,

    eventResizableFromStart: true,

    dragScroll: false,

    dayMaxEvents: 2,

    navLinks: true,

    fixedWeekCount: false,

    eventClassNames({ event: calendarEvent }) {
      const colorName = calendarsColor[calendarEvent._def.extendedProps.status]

      return [
        // Background Color
        `bg-light-${colorName}`,
      ]
    },

    eventContent({ event: calendarEvent }) {
      // console.log('-------', calendarEvent)
      // eslint-disable-next-line no-use-before-define
      // formatTime(calendarEvent.extendedProps)
      let arrayOfDomNodes = []
      // image event
      const imgEventWrap = document.createElement('i')
      // imgEventWrap.classList = 'fas fa-envelope'
      imgEventWrap.innerHTML = '<span style="margin-right: 5px;" />'
      // title event
      const titleEvent = document.createElement('span')
      // eslint-disable-next-line no-underscore-dangle
      if (calendarEvent._def.title) {
        titleEvent.classList = 'title-event'
        // eslint-disable-next-line no-underscore-dangle
        // eslint-disable-next-line no-use-before-define
        titleEvent.innerHTML = formatTime(calendarEvent.extendedProps) + calendarEvent._def.title
        // titleEvent.innerHTML = formatTime(calendarEvent.extendedProps) + calendarEvent._def.title
      }
      arrayOfDomNodes = [imgEventWrap, titleEvent]

      /* add attribute id and date */
      imgEventWrap.setAttribute('data-shift-id', calendarEvent.id)
      // imgEventWrap.setAttribute('data-shift-date', moment(calendarEvent.start).format('YYYY-MM-DD H:mm:ss'))
      imgEventWrap.setAttribute('data-shift-date', moment(calendarEvent.start).format('YYYY-MM-DD'))
      // console.log('calendarEvent', moment(calendarEvent.start).format('YYYY-MM-DD H:mm:ss'))

      imgEventWrap.addEventListener('click', event => {
        const tempEvent = setRecurrInput(grabEventDataFromEventApi(calendarEvent))
        // console.log('tempEvent', tempEvent)
        store.commit('caregiverShift/setEvent', tempEvent)
        openMail(calendarEvent)
        event.stopPropagation()
      })

      return { domNodes: arrayOfDomNodes }
    },

    moreLinkClick(info) {
      // setTimeout(() => {
      // const popupElement = document.querySelector('.fc-popover')
      // const modalElement = document.querySelector('#see-more-modal')
      // // document.querySelector('.fc-popover') ? document.querySelector('.fc-popover').classList.add('d-none') : '' /* remove MORE popover */

      // // popupElement.document.querySelector('.fc-popover').classList.add('d-none')
      // // modalElement.document.querySelector('#see-more-modal___BV_modal_content_').classList.add('d-none')

      // modalElement.querySelector('#see-more-modal___BV_modal_header_ h5').innerHTML = popupElement.querySelector('.fc-popover-header .fc-popover-title').innerHTML
      // modalElement.querySelector('div #see-more-modal___BV_modal_body_').innerHTML = popupElement.querySelector('.fc-popover-body').innerHTML
      // modalElement.querySelector('div #see-more-modal___BV_modal_body_').classList.add('m-1')
      // modalElement.querySelectorAll('div a').forEach(element => {
      //   element.querySelectorAll('i')[0].style.marginLeft = '10px'
      //   element.querySelectorAll('i')[0].style.marginRight = '10px'
      //   const shiftData = {}
      //   shiftData.id = element.querySelectorAll('i')[0].getAttribute('data-shift-id')
      //   shiftData.date = element.querySelectorAll('i')[0].getAttribute('data-shift-date')
      //   const shifts = grabEventfromModal(store.state.caregiverShift.shifts.filter(f => f.id === shiftData.id && f.start.includes(shiftData.date)))
      //   element.addEventListener('click', event => {
      //     seeMoreModal.value = false
      //     const tempEvent = setRecurrInput(shifts)
      //     tempEvent.extendedProps.recurrence = tempEvent.extendedProps.recurrence === '1'
      //     store.commit('caregiverShift/setEvent', tempEvent)
      //     store.commit('caregiverShift/setIsEventHandlerSidebarActive', true)
      //     store.commit('caregiverShift/openShift')
      //   })
      //   element.querySelectorAll('i')[0].addEventListener('click', event => {
      //     seeMoreModal.value = false
      //     const tempEvent = setRecurrInput(shifts)
      //     console.log('tempEvent', tempEvent)
      //     store.commit('caregiverShift/setEvent', tempEvent)
      //     openMail(shifts)
      //     event.stopPropagation()
      //   })
      // })
      // document.querySelector('.modal-dialog').classList.add('d-none')
      // document.querySelector('div .modal-dialog').style.display = 'none'

      //   seeMoreModal.value = true
      //   // document.querySelector('#app').style.overflow = 'overlay'
      //   // document.querySelector('#app').style.overflow = ''
      //   setTimeout(() => {
      //     // document.querySelector('#app').style.overflow = 'overlay'

      //     seeMoreModal.value = false
      //   // document.querySelector('div .modal-dialog').style.display = 'none'
      //   },
      //   0.000000001)
      // },
      // 0.00000001)
      // document.querySelector('#app').style.overflow = 'overlay'

      // seeMoreModal.value = true
      // seeMoreModal.value = false
    },

    eventClick({ event: clickedEvent }) {
      document.querySelector('.fc-popover') ? document.querySelector('.fc-popover').classList.add('d-none') : '' /* remove MORE popover */
      // document.querySelector('.fc-popover') ? document.querySelector('.fc-popover').classList.add('h-auto') : '' /* remove MORE popover */
      // ASSIGNING DEFAULT VALUE TO PREVENT UNDEFINED
      const tempEvent = setRecurrInput(grabEventDataFromEventApi(clickedEvent))
      tempEvent.extendedProps.recurrence = tempEvent.extendedProps.recurrence === '1'
      store.commit('caregiverShift/setEvent', tempEvent)
      store.commit('caregiverShift/setIsEventHandlerSidebarActive', true)
      store.commit('caregiverShift/openShift')
    },

    dateClick(info) { /* trigger when click empty date */
      // document.querySelector('.fc-popover') ? document.querySelector('.fc-popover').classList.add('d-none') : '' /* remove MORE popover */
      // const tempEvent = store.state.caregiverShift.blankEvent
      // tempEvent.start = info.date
      // tempEvent.end = info.date
      // tempEvent.extendedProps.caregiver = store.state.caregiver.caregiver.id /* Automatic set caregiver id */
      // tempEvent.extendedProps.client = null
      // tempEvent.extendedProps.status = '1'
      // store.commit('caregiverShift/setEvent', tempEvent)
      // store.commit('caregiverShift/setIsEventHandlerSidebarActive', true)
      // store.commit('caregiverShift/openShift')
    },
  })

  const formatTime = extendedProps => {
    const temp = extendedProps
    // eslint-disable-next-line radix
    const start = extendedProps.start_time.substring(2, 0) === '00' ? 12 : parseInt(extendedProps.start_time.substring(2, 0))
    // eslint-disable-next-line radix
    const end = extendedProps.end_time.substring(2, 0) === '00' ? 12 : parseInt(extendedProps.end_time.substring(2, 0))

    return extendedProps.start_time && extendedProps.start_time
      ? `${(start > 12 ? `${start - 12}p ` : `${start}a `)} - ${end > 12 ? `${end - 12}p ` : `${end}a `} `
      : ''
  }
  let activeFilters = []
  let showAllToggle = 0
  const filterShifts = async legendId => {
    showAllToggle = 1
    if (legendId == '1') {
      if (activeFilters.includes(legendId)) {
        // activeFilters.pop(legendId)
        const index = activeFilters.indexOf(legendId)
        if (index > -1) { // only splice array when item is found
          activeFilters.splice(index, 1) // 2nd parameter means remove one item only
        }
        const element = document.querySelector('[dir] .legend .bg-light-scheduled1')
        element.classList.add('outlined-1')
      } else {
        activeFilters.push(legendId)
        const element = document.querySelector('[dir] .legend .bg-light-scheduled1')
        element.classList.remove('outlined-1')
      }
    }
    if (legendId == '2') {
      if (activeFilters.includes(legendId)) {
        // activeFilters.pop(legendId)
        const index = activeFilters.indexOf(legendId)
        if (index > -1) { // only splice array when item is found
          activeFilters.splice(index, 1) // 2nd parameter means remove one item only
        }
        const element = document.querySelector('[dir] .legend .bg-light-in-progress1')
        element.classList.add('outlined-2')
      } else {
        activeFilters.push(legendId)
        const element = document.querySelector('[dir] .legend .bg-light-in-progress1')
        element.classList.remove('outlined-2')
      }
    }
    if (legendId == '3') {
      if (activeFilters.includes(legendId)) {
        // activeFilters.pop(legendId)
        const index = activeFilters.indexOf(legendId)
        if (index > -1) { // only splice array when item is found
          activeFilters.splice(index, 1) // 2nd parameter means remove one item only
        }
        const element = document.querySelector('[dir] .legend .bg-light-completed1')
        element.classList.add('outlined-3')
      } else {
        activeFilters.push(legendId)
        const element = document.querySelector('[dir] .legend .bg-light-completed1')
        element.classList.remove('outlined-3')
      }
    }
    if (legendId == '4') {
      if (activeFilters.includes(legendId)) {
        // activeFilters.pop(legendId)
        const index = activeFilters.indexOf(legendId)
        if (index > -1) { // only splice array when item is found
          activeFilters.splice(index, 1) // 2nd parameter means remove one item only
        }
        const element = document.querySelector('[dir] .legend .bg-light-m-clock-in1')
        element.classList.add('outlined-4')
      } else {
        activeFilters.push(legendId)
        const element = document.querySelector('[dir] .legend .bg-light-m-clock-in1')
        element.classList.remove('outlined-4')
      }
    }
    if (legendId == '5') {
      if (activeFilters.includes(legendId)) {
        // activeFilters.pop(legendId)
        const index = activeFilters.indexOf(legendId)
        if (index > -1) { // only splice array when item is found
          activeFilters.splice(index, 1) // 2nd parameter means remove one item only
        }
        const element = document.querySelector('[dir] .legend .bg-light-m-clock-out1')
        element.classList.add('outlined-5')
      } else {
        activeFilters.push(legendId)
        const element = document.querySelector('[dir] .legend .bg-light-m-clock-out1')
        element.classList.remove('outlined-5')
      }
    }
    if (legendId == '6') {
      if (activeFilters.includes(legendId)) {
        // activeFilters.pop(legendId)
        const index = activeFilters.indexOf(legendId)
        if (index > -1) { // only splice array when item is found
          activeFilters.splice(index, 1) // 2nd parameter means remove one item only
        }
        const element = document.querySelector('[dir] .legend .bg-light-open-shift1')
        element.classList.add('outlined-6')
      } else {
        activeFilters.push(legendId)
        const element = document.querySelector('[dir] .legend .bg-light-open-shift1')
        element.classList.remove('outlined-6')
      }
    }
    if (legendId == '7') {
      if (activeFilters.includes(legendId)) {
        // activeFilters.pop(legendId)
        const index = activeFilters.indexOf(legendId)
        if (index > -1) { // only splice array when item is found
          activeFilters.splice(index, 1) // 2nd parameter means remove one item only
        }
        const element = document.querySelector('[dir] .legend .bg-light-c-client1')
        element.classList.add('outlined-7')
      } else {
        activeFilters.push(legendId)
        const element = document.querySelector('[dir] .legend .bg-light-c-client1')
        element.classList.remove('outlined-7')
      }
    }
    if (legendId == '8') {
      if (activeFilters.includes(legendId)) {
        // activeFilters.pop(legendId)
        const index = activeFilters.indexOf(legendId)
        if (index > -1) { // only splice array when item is found
          activeFilters.splice(index, 1) // 2nd parameter means remove one item only
        }
        const element = document.querySelector('[dir] .legend .bg-light-c-caregiver1')
        element.classList.add('outlined-8')
      } else {
        activeFilters.push(legendId)
        const element = document.querySelector('[dir] .legend .bg-light-c-caregiver1')
        element.classList.remove('outlined-8')
      }
    }
    if (legendId == '10') {
      if (activeFilters.includes(legendId)) {
        // activeFilters.pop(legendId)
        const index = activeFilters.indexOf(legendId)
        if (index > -1) { // only splice array when item is found
          activeFilters.splice(index, 1) // 2nd parameter means remove one item only
        }
        const element = document.querySelector('[dir] .legend .bg-light-pending-confirmation1')
        element.classList.add('outlined-10')
      } else {
        activeFilters.push(legendId)
        const element = document.querySelector('[dir] .legend .bg-light-pending-confirmation1')
        element.classList.remove('outlined-10')
      }
    }
    if (legendId == null) {
      // const elem = document.querySelectorAll('[dir] .legend .legend-badge')
      const showAll = document.querySelector('[dir] .legend .bg-light-show-all1')
      if (!showAll.classList.contains('outlined-0')) {
        showAllToggle = 1
        // eslint-disable-next-line no-plusplus
        const element1 = document.querySelector('[dir] .legend .bg-light-scheduled1')
        element1.classList.remove('outlined-1')

        const element2 = document.querySelector('[dir] .legend .bg-light-in-progress1')
        element2.classList.add('outlined-2')

        const element3 = document.querySelector('[dir] .legend .bg-light-completed1')
        element3.classList.add('outlined-3')

        const element4 = document.querySelector('[dir] .legend .bg-light-m-clock-in1')
        element4.classList.add('outlined-4')

        const element5 = document.querySelector('[dir] .legend .bg-light-m-clock-out1')
        element5.classList.add('outlined-5')

        const element6 = document.querySelector('[dir] .legend .bg-light-open-shift1')
        element6.classList.add('outlined-6')

        const element7 = document.querySelector('[dir] .legend .bg-light-c-client1')
        element7.classList.add('outlined-7')

        const element8 = document.querySelector('[dir] .legend .bg-light-c-caregiver1')
        element8.classList.add('outlined-8')

        const element9 = document.querySelector('[dir] .legend .bg-light-pending-confirmation1')
        element9.classList.add('outlined-10')

        const element10 = document.querySelector('[dir] .legend .bg-light-show-all1')
        element10.classList.add('outlined-0')
        activeFilters = []
        activeFilters = ['1']
        // document.querySelector('[dir] .legend .bg-light-scheduled1').style.filter = 'brightness(100%)'
      } else {
        showAllToggle = 0
        // eslint-disable-next-line no-plusplus
        // for (let i = 0; i < elem.length; i++) {
        //   elem[i].style.filter = 'brightness(70%)'
        // }
        // document.querySelector('[dir] .legend .bg-light-show-all1').style.filter = 'brightness(100%)'

        const element1 = document.querySelector('[dir] .legend .bg-light-scheduled1')
        element1.classList.add('outlined-1')

        const element2 = document.querySelector('[dir] .legend .bg-light-in-progress1')
        element2.classList.add('outlined-2')

        const element3 = document.querySelector('[dir] .legend .bg-light-completed1')
        element3.classList.add('outlined-3')

        const element4 = document.querySelector('[dir] .legend .bg-light-m-clock-in1')
        element4.classList.add('outlined-4')

        const element5 = document.querySelector('[dir] .legend .bg-light-m-clock-out1')
        element5.classList.add('outlined-5')

        const element6 = document.querySelector('[dir] .legend .bg-light-open-shift1')
        element6.classList.add('outlined-6')

        const element7 = document.querySelector('[dir] .legend .bg-light-c-client1')
        element7.classList.add('outlined-7')

        const element8 = document.querySelector('[dir] .legend .bg-light-c-caregiver1')
        element8.classList.add('outlined-8')

        const element9 = document.querySelector('[dir] .legend .bg-light-pending-confirmation1')
        element9.classList.add('outlined-10')

        const element = document.querySelector('[dir] .legend .bg-light-show-all1')
        element.classList.remove('outlined-0')
        activeFilters = []
      }
    }
    // console.log(activeFilters.length, showAllToggle)
    if (activeFilters.length < 9 && showAllToggle > 0) {
      const element = document.querySelector('[dir] .legend .bg-light-show-all1')
      element.classList.add('outlined-0')
      // console.log('testing1')
    } else {
      const element = document.querySelector('[dir] .legend .bg-light-show-all1')
      element.classList.remove('outlined-0')
      // console.log('testing2')
    }

    caregiverShifts.value = await store.state.caregiverShift.shifts.filter(shift => {
      let keep = true
      if (showAllToggle > 0) {
        keep = keep && activeFilters.includes(shift.status.toString())
      }
      // keep = keep && f.status == legendId
      return keep
    })
  }
  // const temp = $('a.fc-daygrid-more-link.fc-more-link')
  // console.log('temp', temp)
  return {
    calendarOptions,
    refCalendar,
    seeMoreModal,
    filterShifts,
    caregiverShifts,
  }
}
