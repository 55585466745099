<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="4"
        class="d-flex justify-content-between flex-column my-1"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start ml-1">
          <b-avatar
            :src=" userData.profilepicture ? `${url}upload/${userData.id}/${userData.profilepicture}` : null"
            :text="avatarText(`${userData.firstname.slice(0,1)} ${userData.lastname.slice(0,1)}`)"
            size="104px"
            :variant="['Teminated', 'Inactive','Resigned'].includes(userData.employmentstatus) ? 'danger' : 'primary' "
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h3>
                {{ userData.firstname }} {{ userData.lastname }}
              </h3>
              <span class="card-text text-primary">{{ userData.emailaddress }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <div v-if="!['Active', 'Applicant', 'New', 'Pending'].includes(userData.employmentstatus)">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-activate
                  variant="primary"
                >
                  Activate
                </b-button>
              </div>
              <div
                v-else
                class="d-flex flex-wrap"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="secondary"
                  class="mr-sm-1 sm-w-100"
                  @click="$store.commit('caregiver/SET_isCaregiverHandlerSidebarActive', true)"
                >
                  Edit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-deactivate
                  variant="outline-danger"
                >
                  Deactivate
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="8"
        class="my-1"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th
              class="pb-50 text-nowrap gray-1"
              width="15%"
            >
              <feather-icon
                size="19"
                icon="HomeIcon"
                class="mr-75"
              />
              <span class="font-weight-bolder">Address:</span>
            </th>
            <th>
              <span class="pb-50 text-capitalize">{{ userData.address }}</span>
            </th>
          </tr>
          <tr>
            <th class="pb-50 text-nowrap gray-1">
              <feather-icon
                size="19"
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bolder mr-1">Mobile:</span>
            </th>
            <th>
              <span class="pb-50 text-capitalize">{{ userData.mobilenumber }}</span>
            </th>
          </tr>
          <tr>
            <th class="pb-50 text-nowrap gray-1 d-flex">
              <div>
                <feather-icon
                  size="19"
                  icon="MapPinIcon"
                  class="mr-75"
                />
                <span class="font-weight-bolder mr-1">Locations:</span>
              </div>
            </th>
            <th>
              <div>
                <span
                  v-if="locations.length == 0"
                  class="pb-50 text-capitalize"
                >N/A</span>
                <span
                  v-for="location in locations"
                  v-else
                  :key="location"
                >
                  <span class="pb-50 text-capitalize">{{ location }}</span><br>
                </span>
              </div>
            </th>
            <!-- <span class="pb-50 text-capitalize">{{ moment().format('YYYY-MM-DD') }}</span> -->
          </tr>
          <tr>
            <th class="d-flex">
              <!-- <feather-icon
                size="19"
                icon="UserIcon"
                class="mr-75"
              /> -->
              <div>
                <b-img
                  :src="require('@/assets/images/icons/id_icon.svg')"
                  class="mr-75"
                  alt="icon-npi"
                />
                <span class="font-weight-bolder mr-1 gray-1">Licenses:</span>
              </div>
            </th>
            <th>
              <div
                class="pb-50 text-nowrap text-capitalize"
              >
                <span
                  v-for="license in formatNPI(userData.license)"
                  :key="license.v"
                >
                  <span v-if="license.v ==='N/A'">{{ license.v }}</span>
                  <span v-else><b>{{ license.l }}:</b> {{ license.v }} </span><br>
                </span>
              </div>
            </th>
          </tr>
        </table>
      </b-col>
    </b-row>
    <!-- activate modal  -->
    <b-modal
      id="modal-activate"
      title="Activate"
      centered
    >
      <h5 class="my-3">
        You are about to activate caregiver account <b>{{ `${userData.firstname} ${userData.lastname}` }}</b>
      </h5>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-dark"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          @click="$store.dispatch('caregiver/setCaregiverStatus', {setActive : true, caregiverId: userData.id}); ok()"
        >
          Activate
        </b-button>
      </template>
    </b-modal>
    <!-- deactivate modal  -->
    <b-modal
      id="modal-deactivate"
      title="Deactivate"
      centered
    >
      <div class="mx-0.5 my-1">
        <span>
          You are about to deactivate the caregiver account of <b>{{ `${userData.firstname} ${userData.lastname}` }}</b>.<br>
          Select the reason for deactivation below:
        </span>
        <b-form-group class="mt-1">
          <b-form-radio-group
            v-model="employmentStatus"
            :options="['Inactive','Terminated - Quit','Terminated - for Cause', 'Terminated - Insufficient Work Available', 'Other:']"
            name="radios-stacked"
            stacked
          />
        </b-form-group>
        <b-form-textarea
          v-if="employmentStatus === 'Other:'"
          v-model="otherReason"
          rows="2"
        />
      </div>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-dark"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="danger"
          :disabled="employmentStatus === null"
          @click="$store.dispatch('caregiver/setCaregiverStatus', {setActive : false, employmentStatus: employmentStatus, caregiverId: userData.id, otherReason: otherReason}); employmentStatus = null; ok()"
        >
          Deactivate
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import Vue from 'vue'
import {
  BModal, BCard, BButton, BAvatar, BRow, BCol, BFormRadioGroup, BFormGroup, BFormTextarea, BImg,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import { ref, computed } from '@vue/composition-api'
import moment from 'moment'
// import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BModal, BFormRadioGroup, BFormGroup, BFormTextarea, BImg,
  },
  directives: {
    Ripple,
  },
  setup() {
    const userData = ref(computed(() => store.state.caregiver.caregiver))
    // const location = ref(computed(() => store.state.caregiverShift.shifts.filter(shift => moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').isBetween(moment(shift.start, 'YYYY-MM-DD'), moment(shift.end, 'YYYY-MM-DD'))).map(f => f.location)))
    // const location = ref(computed(() => store.state.caregiverShift.shifts.filter(shift => moment().isSameOrBefore(moment(shift.end, 'YYYY-MM-DD')) && moment().isSameOrAfter(moment(shift.start, 'YYYY-MM-DD'))).map(f => f.location)))
    const locations = ref(computed(() => store.state.caregiverShift.shifts.filter(shift => (moment(shift.start).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) && ['1', '2', '3', '4', '5'].includes(shift.status)).map(f => f.location)))
    // console.log('()()()()()()()()', moment().format('YYYY-MM-DD'))

    const formatNPI = ids => {
      const idTest = []
      if (ids) {
        if (ids.licenseNoCNA !== '') idTest.push({ l: 'CNA', v: ids.licenseNoCNA })
        if (ids.licenseNoHCA !== '') idTest.push({ l: 'HCA', v: ids.licenseNoHCA })
        if (ids.licenseNoHHA !== '') idTest.push({ l: 'HHA', v: ids.licenseNoHHA })
        if (ids.licenseNoRNLVN !== '') idTest.push({ l: 'RNLVN', v: ids.licenseNoRNLVN })
      } else {
        idTest.push({ v: 'N/A' })
      }

      if (idTest.length > 2) idTest.splice(2)

      // return idTest.join(', ')
      return idTest
    }
    return {
      avatarText,
      userData,
      url: Vue.prototype.$mainUrl,
      moment,
      employmentStatus: ref(null),
      locations,
      otherReason: ref(''),
      formatNPI,
    }
  },
}
</script>
<style lang="scss">
[dir] .modal-body{
    text-align: left;
    /* font: normal normal normal 14px/24px Montserrat; */
    letter-spacing: 0px;
    // color: #33475B !important;
    .text-bold{
      text-align: left;
      font: normal normal 600 14px/24px Montserrat;
      letter-spacing: 0px;
      // color: #33475B;
  }
}
[dir] #modal-deactivate{
  textarea{
    margin-top: -10px !important;
    margin-left: 1.4rem !important;
    width: 95%;
  }
}
[dir] .custom-radio .custom-control-label {
  font: normal normal normal 14px/18px Montserrat;
}
[dir] .text-capitalize{
  font: normal normal normal 14px/24px Montserrat !important;
  // color: #33475B !important;
}
</style>
