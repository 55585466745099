<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="$store.state.caregiverShift.isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $store.commit('caregiverShift/setIsEventHandlerSidebarActive', val)"
    >
      <template #default="">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            <!-- <span>Shift Details</span> -->
            <!-- {{ $store.state.caregiverShift.isMail }} -->
            <span>Shift Calendar - {{ eventLocal.extendedProps ? $store.state.caregiverShift.calendarOptions.filter(f => f.id == eventLocal.extendedProps.status).map(f => f.label)[0] : '' }}</span>
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="$store.commit('caregiverShift/setIsEventHandlerSidebarActive', false)"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          ref="refFormObserver"
        >

          <!-- Send Message Form -->
          <b-form
            v-show="$store.state.caregiverShift.isMail"
            class="p-2"
          >
            <div>
              <b-form-group
                label="Preferred Delivery Method"
                class="mt-0 calendar-label"
              >
                <div class="demo-inline-spacing custom-calendar-radio">
                  <b-form-radio
                    v-model="eventLocal.extendedProps.delivery"
                    name="delivery-method"
                    value="both"
                    disabled
                  >
                    Both
                  </b-form-radio>
                  <b-form-radio
                    v-model="eventLocal.extendedProps.delivery"
                    name="delivery-method"
                    value="email"
                  >
                    Email
                  </b-form-radio>
                  <b-form-radio
                    v-model="eventLocal.extendedProps.delivery"
                    name="delivery-method"
                    value="sns"
                    disabled
                  >
                    SNS
                  </b-form-radio>
                </div>
              </b-form-group>

              <!-- Sent To -->
              <validation-provider
                #default="validationContext"
                name="Sent To"
                rules=""
              >
                <b-form-group
                  label="Sent To"
                  label-for="sent-to"
                >
                  <b-form-input
                    id="sent-to"
                    v-model="eventLocal.extendedProps.caregiverEmail"
                    :state="getValidationState(validationContext)"
                    trim
                    readonly
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Sent Replies To -->
              <validation-provider
                #default="validationContext"
                name="Sent Replies To"
                rules=""
              >
                <b-form-group
                  label="Sent Replies To"
                  label-for="replies-to"
                >
                  <b-form-input
                    id="replies-to"
                    v-model="eventLocal.extendedProps.userEmail"
                    :state="getValidationState(validationContext)"
                    trim
                    readonly
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Relates To -->
              <!-- <b-form-group
                  label="Relates To"
                  label-for="relates-to"
                >
                  <b-form-input
                    id="relates-to"
                    v-model="eventLocal.extendedProps.relatesTo"
                    trim
                    placeholder="Add People"
                  />
                </b-form-group> -->

              <b-form-group
                label="Relates To"
                label-for="relates-to"
              >
                <v-select
                  v-model="eventLocal.extendedProps.clientEmail"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  placeholder="Add People"
                  :options="$store.state.caregiverShift.clientOptions"
                  :reduce="option => option.email"
                  input-id="client"
                />
              </b-form-group>

              <!-- Subject -->
              <b-form-group
                label="Subject"
                label-for="subject"
              >
                <b-form-input
                  id="subject"
                  v-model="eventLocal.extendedProps.subject"
                  trim
                />
              </b-form-group>

              <!-- Message -->
              <b-form-group
                label="Message"
                label-for="task-description"
              >
                <quill-editor
                  id="quill-content1"
                  v-model="eventLocal.extendedProps.message"
                  :options="editorOption1"
                  class="border-bottom-0"
                />
                <div
                  id="quill-toolbar1"
                  class="d-flex justify-content-end border-top-0"
                >
                  <!-- Add a bold button -->
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-underline" />
                  <button class="ql-align" />
                  <button class="ql-link" />
                </div>
              </b-form-group>

              <!-- Signature -->
              <b-form-group
                label="Signature "
                label-for="task-description"
              >
                <quill-editor
                  id="quill-content2"
                  v-model="eventLocal.extendedProps.signature"
                  :options="editorOption2"
                  class="border-bottom-0"
                />
                <div
                  id="quill-toolbar2"
                  class="d-flex justify-content-end border-top-0"
                >
                  <!-- Add a bold button -->
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-underline" />
                  <button class="ql-align" />
                  <button class="ql-link" />
                </div>
              </b-form-group>

              <span class="text-signature">
                Changes here will only apply to the current messages
                Go to Agency Settings to change signature permanently.
              </span>

              <div class="mt-1">
                <label for="files">Attach File/s</label>
                <b-form-file
                  id="files"
                  v-model="eventLocal.extendedProps.files"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  multiple
                />
              </div>
              <!-- {{ eventLocal.extendedProps.files }} -->
            </div>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                class="mr-2"
                @click="onSubmit"
              >
                Send
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-dark"
                @click="$store.commit('caregiverShift/setIsEventHandlerSidebarActive', false)"
              >
                Cancel
              </b-button>
            </div>

          </b-form>

          <!-- Shift Form -->
          <b-form
            v-show="$store.state.caregiverShift.isShift"
            class="p-2"
          >
            <div>
              <b-form-group v-if="eventLocal.extendedProps">
                <!-- {{ eventLocal.extendedProps.status }} -->
                <b-row
                  v-if="![1, 2, 3, 4, 5, 10, '1', '2', '3', '4', '5', '10'].includes(eventLocal.extendedProps.status)"
                  id="sidebar-warning"
                  class="mb-1 mx-0 py-1 text-danger"
                >
                  <b-col
                    cols="1"
                    class=""
                  >
                    <feather-icon
                      icon="AlertCircleIcon"
                      size="25"
                      class="text-danger"
                    />
                  </b-col>
                  <b-col
                    cols="10"
                  >
                    <span
                      v-if="eventLocal.extendedProps.status === '6' || eventLocal.extendedProps.status === 6"
                      class="text-danger"
                    >
                      Admin will assign a caregiver that matches the client's preferences.
                    </span>
                    <span
                      v-if="eventLocal.extendedProps.status === '7' || eventLocal.extendedProps.status === 7"
                      class="text-danger"
                    >
                      This shift was cancelled by the client or the Family PIC.
                    </span>
                    <span
                      v-if="eventLocal.extendedProps.status === '8' || eventLocal.extendedProps.status === 8"
                      class="text-danger"
                    >
                      This shift was cancelled by the caregiver. Admin will assign a new caregiver.
                    </span>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>

            <!-- shift details -->
            <span class="info-primary">Shift Details</span>
            <div v-if="eventLocal.extendedProps">
              <!-- client -->
              <div class="d-flex mt-1">
                <span
                  v-if="eventLocal.extendedProps.client_fullname"
                  class="mr-1"
                >
                  <b-avatar
                    size="34"
                    :src="eventLocal.extendedProps.clientPicture ? `${url}upload/clients/${eventLocal.extendedProps.clientPicture}` : ''"
                    variant="primary"
                    :text="`${eventLocal.extendedProps.client_fullname ? returnFullname(eventLocal.extendedProps.client_fullname) : ''}`"
                  />
                  <!-- {{ returnFullname(eventLocal.extendedProps.client_fullname) }} -->
                  <!-- <b-avatar
                    size="34"
                    :src="eventLocal.extendedProps.clientPicture ? `${url}upload/clients/${eventLocal.extendedProps.clientPicture}` : ''"
                    variant="primary"
                    :text="`Test`"
                  /> -->
                </span>
                <span>
                  <span class="info-primary">
                    {{ `${eventLocal.extendedProps.client_fullname ? eventLocal.extendedProps.client_fullname.split(', ')[1] : ''} ${eventLocal.extendedProps.client_fullname ? eventLocal.extendedProps.client_fullname.split(', ')[0] : ''}` }}
                  </span><br>
                  <span class="text-muted info-secondary">
                    Client
                  </span>
                </span>
              </div>
              <!-- start date -->
              <div class="d-flex mt-1">
                <span class="mr-1 f-icon-container">
                  <span class="m-auto">
                    <feather-icon
                      icon="CalendarIcon"
                      size="25"
                      class="f-icon"
                    />
                  </span>
                </span>
                <span>
                  <span class="info-primary">
                    {{ moment(eventLocal.start).format('ddd, MMMM DD, YYYY') }}
                  </span><br>
                  <span class="text-muted info-secondary">
                    {{ `${moment(eventLocal.start, ["HH.mm"]).format("hh:mm a")} - ${moment(eventLocal.end, ["HH.mm"]).format("hh:mm a")}` }}
                  </span>
                </span>
              </div>
              <!-- location -->
              <div class="d-flex mt-1">
                <span class="mr-1 f-icon-container">
                  <span class="m-auto">
                    <feather-icon
                      icon="MapPinIcon"
                      size="25"
                      class="f-icon"
                    />
                  </span>
                </span>
                <span class="my-auto">
                  <span
                    v-if="eventLocal.extendedProps.location === ''"
                    class="info-primary"
                  >
                    N/A
                  </span>
                  <span
                    v-else
                    class="info-primary"
                  >
                    {{ eventLocal.extendedProps.location }}
                  </span>
                </span>
              </div>
            </div>
            <hr class="my-1">

            <div>
              <div style="min-height: 35vh">
                <h4 v-if="eventLocal.extendedProps.status === '1'">
                  Shift Task/s
                </h4>
                <h4 v-else>
                  Task/s
                </h4>
                <b-list-group
                  v-if="taskOptions"
                  flush
                >
                  <b-list-group-item
                    v-for="(taskOption, index) in taskOptions"
                    :key="taskOption.id"
                    v-model="selectedTask"
                    class="d-flex text-truncate"
                  >
                    <span class="mr-1">{{ index + 1 }}.</span>
                    <span>
                      {{ stripTags(taskOption.description) }}
                    </span>
                  </b-list-group-item>
                </b-list-group>

                <b-list-group
                  v-if="shiftsTaskOptions"
                  flush
                >
                  <b-list-group-item
                    v-for="(shiftTaskOption, index) in shiftsTaskOptions"
                    :key="shiftTaskOption.task"
                    v-model="selectedTask"
                    class="d-flex text-truncate"
                  >
                    <span class="mr-1">{{ index + 1 }}.</span>
                    <span>
                      {{ shiftTaskOption.task }}
                    </span>
                  </b-list-group-item>
                </b-list-group>
                <!-- {{ selectedTask }} -->
              </div>
            </div>
          </b-form>

          <!-- Form Actions -->
          <div class="d-flex mt-2 p-2">
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              class="mr-2"
              @click="$store.commit('caregiverShift/openTask')"
            >
              Next
            </b-button> -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="secondary"
              @click="$store.commit('caregiverShift/setIsEventHandlerSidebarActive', false)"
            >
              OK
            </b-button>
          </div>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import store from '@/store'
import {
  ref, toRefs,
} from '@vue/composition-api'
import {
  BListGroup, BAvatar, BListGroupItem, BFormFile, BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { quillEditor } from 'vue-quill-editor'
// import CaregiverShiftStoreModule from '../../CaregiverShiftStoreModule'
import moment from 'moment'
import caregiverStoreInit from '../../caregiverStoreInit'
import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    // BTooltip,
    BListGroup,
    BListGroupItem,
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormTextarea,
    vSelect,
    // flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
    BFormRadio,
    // BCard,
    // BFormCheckboxGroup,
    // BFormTimepicker,
    quillEditor,
    BFormFile,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  setup(props, { emit }) {
    document.querySelector('#app').style.overflow = 'overlay'
    caregiverStoreInit()

    const clearFormData = ref(null)
    const formObserver = ref(null)

    const {
      eventLocal,
      resetEventLocal,

      // UI
      onSubmit,
      guestsOptions,
      /* tasks */
      createTask,
      selectedTask,
      taskOptions,
      shiftsTaskOptions,
      caregiverTask,
      pushCaregiverTask,
      createTaskCancel,
      removeShifTask,
      fetchShiftTasks,
      checkRequiredForms,
    } = useCalendarEventHandler(toRefs(props), clearFormData, formObserver, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    const stripTags = html => {
      const tmp = document.implementation.createHTMLDocument('New').body
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText || ''
    }

    clearFormData.value = clearForm
    formObserver.value = refFormObserver

    const returnFullname = name => {
      // console.log(name)
      // eslint-disable-next-line eqeqeq
      if (typeof name === 'string') {
        let initials = ''
        const nameArray = name.split(/[ ,]+/)

        if (nameArray.length >= 2) {
          const firstName = nameArray[0]
          const lastName = nameArray[1]

          // eslint-disable-next-line no-undef
          initials = lastName[0].toUpperCase() + firstName[0].toUpperCase()
        }

        return initials
      }

      return ''
    }

    return {
    // Add New Event
      eventLocal,
      onSubmit,
      guestsOptions,

      // Form Validation
      resetForm,
      refFormObserver,
      formObserver,
      checkRequiredForms,
      getValidationState,

      required,
      email,
      url,
      moment,
      /* tasks */
      createTask,
      selectedTask,
      taskOptions,
      shiftsTaskOptions,
      caregiverTask,
      pushCaregiverTask,
      createTaskCancel,
      stripTags,
      fetchShiftTasks,
      /* send message */
      editorOption: ref({
        modules: {
          toolbar: '#quill-toolbar',
        },
        placeholder: 'Write your message',
      }),
      editorOption1: ref({
        modules: {
          toolbar: '#quill-toolbar1',
        },
        placeholder: 'Write your message',
      }),
      editorOption2: ref({
        modules: {
          toolbar: '#quill-toolbar2',
        },
        placeholder: 'Write your signature',
      }),
      removeShifTask,
      isNewTask: ref(() => (!eventLocal.value.id)), /* check if new tasks */
      returnFullname,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
</style>
<style scoped>
.create-task{
  color: #569099;
  cursor: pointer;
}
.addtask{
    padding: 0.486rem 0.8rem;
}
</style>
<style>
.quill-editor{
  height: 148px;
}
#quill-content2{
  height: 74px;
}
[dir] .ql-container.ql-snow {
    border-bottom: 0px;
}
.custom-calendar-radio .custom-radio{
  margin-top: 3px;
  margin-bottom: 5px;
  margin-right: 15px;
}
[dir] .custom-radio .custom-control-label{
 font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #33475B;
  opacity: 1;
}
.calendar-label .bv-no-focus-ring{
  font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #33475B;
  opacity: 1;
  padding-top: 5px !important;
}
.text-signature{
  text-align: left;
  font: italic normal normal 10px/15px Montserrat;
  letter-spacing: 0px;
  color: #516F90;
}
.recurr-card{
  border: 1px solid #d8d6de;
}
[dir] .card-body{
  padding: 1rem;
}
</style>
